<style src="./assets/app.css"/>
<script setup>
import {onMounted} from 'vue';
import HeaderComponent from './components/HeaderComponent.vue';

onMounted(() => {
  let scriptElement = document.createElement('script');
  scriptElement.setAttribute('src', '/webView.js');
  document.head.appendChild(scriptElement);
});
</script>
<template>
  <div id="app">
    test
  </div>
</template>